import React from "react";
// import { useLocation } from "react-router-dom";
import './Success.css'

function Success(){

    return (
        
            <div className="div-container">
                <h2 style={{display: 'flex',justifyContent: 'center',marginTop: "20%",}}>
                    Thank you 🎉
                </h2>
                <p className="pTag">A reciept has been emailed </p>
            </div>

        
    );
}

export default Success;